import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index/index.vue'
import index1 from '../views/index1/index.vue'
import index2 from '../views/index2/index.vue'
import index3 from '../views/index3/index.vue'
import index4 from '../views/index4/index.vue'
import index5 from '../views/index5/index.vue'
import index6 from '../views/index6/index.vue'
import index7 from '../views/index7/index.vue'
import mob from '../views/mob/index.vue'
import mob1 from '../views/mob1/index.vue'
import mob2 from '../views/mob2/index.vue'
import mob3 from '../views/mob3/index.vue'
import mob4 from '../views/mob4/index.vue'
import mob5 from '../views/mob5/index.vue'
import mob6 from '../views/mob6/index.vue'
import mob7 from '../views/mob7/index.vue'

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    redirect: "/index"
  },
  {
    path: '/index',
    name: 'index',
    component: index
  },
  {
    path: '/index1',
    name: 'index1',
    component: index1
  },
  {
    path: '/index2',
    name: 'index2',
    component: index2
  },
  {
    path: '/index3',
    name: 'index3',
    component: index3
  },
  {
    path: '/index4',
    name: 'index4',
    component: index4
  },
  {
    path: '/index5',
    name: 'index5',
    component: index5
  },
  {
    path: '/index6',
    name: 'index6',
    component: index6
  },
  {
    path: '/index7',
    name: 'index7',
    component: index7
  },
  {
    path: '/m/index',
    name: 'mob',
    component: mob
  },
  {
    path: '/m/index1',
    name: 'mob1',
    component: mob1
  },
  {
    path: '/m/index2',
    name: 'mob2',
    component: mob2
  },
  {
    path: '/m/index3',
    name: 'mob3',
    component: mob3
  },
  {
    path: '/m/index4',
    name: 'mob4',
    component: mob4
  },
  {
    path: '/m/index5',
    name: 'mob5',
    component: mob5
  },
  {
    path: '/m/index6',
    name: 'mob6',
    component: mob6
  },
 
  {
    path: '/m/index7',
    name: 'mob7',
    component: mob7
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 移动端和pc端判断与切换
router.beforeEach((to, from, next) => {
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    if(to.path.includes('/m')){
      next()
    }else{
      next('/m'+to.path)
    }
  } else {
    if(to.path.includes('/m')){
      next(to.path.replace('/m',''))
    }else{
      next()
    }
    
  }
  
})

export default router
