<template>
  <div class="page flex-col">
    <div class="group_1 flex-row">
      <div class="image-wrapper_1 flex-col">
        <top-logo class="image_1"></top-logo>
      </div>
      <div class="box_2 flex-col justify-between">
        <top-nav></top-nav>
      </div>
    </div>
    <div class="block_1 flex-col">
      <div class="box_1 flex-row justify-between">
        <div class="section_1 flex-col justify-between">
          <div class="text-wrapper_1">
            <span class="paragraph_1">
              Recruiting&nbsp;partners
              <br />
              <br />
            </span>
            <span class="text_1"></span>
          </div>
          <span class="paragraph_2">
            OKC株式会社では、協業いただける企業様を随時募集しております。
            <br />
            ビジネスパートナーとしてお力添え頂ける企業様からのご連絡を、心よりお待ち申し上げております。
            <br />
            下記のビジネスパートナーお問い合わせフォームから、是非ご連絡を頂きますようお願い申し上げます。
          </span>
        </div>
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/a0e7e29f86ec9b03cdc9280ccd382124.png"
        />
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/51117fe13225e3b609764d6fc0a4e31d.png"
        />
      </div>
      <div class="box_5 flex-col">
        <div class="text-wrapper_2">
          <span class="paragraph_3">
            わたしたちと未来を変える
            <br />
            有望な人材を募集。
            <br />
          </span>
          <span class="paragraph_4"><br /></span>
        </div>
        <div class="box_6 flex-row">
          <img
            class="image_4"
            referrerpolicy="no-referrer"
            src="./assets/img/1f9ace49560d6ec81b406397191993b8.png"
          />
          <div class="text-wrapper_3">Still&nbsp;More</div>
        </div>
      </div>
    </div>
    <div class="block_2 flex-col">
      <div class="text-wrapper_4">
        <span class="paragraph_6">
          Cooperative&nbsp;Consultation
          <br />
        </span>
        <span class="text_3"></span>
      </div>
      <span class="text_4">お客様基本情報</span>
      <div class="block_3 flex-row justify-between">
        <div class="text-wrapper_5">
          <span class="paragraph_7">
            氏名
            <br />
            <br />
          </span>
          <span class="text_5"></span>
        </div>
        <div class="text-wrapper_6">
          <span class="paragraph_8">
            メールアドレス
            <br />
            <br />
          </span>
          <span class="text_6"></span>
        </div>
      </div>
      <div class="image-wrapper_2 flex-row justify-between">
        <img
          class="image_5"
          referrerpolicy="no-referrer"
          src="./assets/img/acf23e6e198524407c79584f595f94d1.png"
        />
        <img
          class="image_6"
          referrerpolicy="no-referrer"
          src="./assets/img/9f8a4abe1d94e61ed384fa0c697cfbc4.png"
        />
      </div>
      <div class="block_4 flex-row">
        <div class="text-wrapper_7">
          <span class="paragraph_9">
            会社団体名
            <br />
            <br />
          </span>
          <span class="text_7"></span>
        </div>
        <div class="text-wrapper_8">
          <span class="paragraph_10">
            お問い合わせ内容
            <br />
            <br />
          </span>
          <span class="text_8"></span>
        </div>
        <div class="text-wrapper_9">
          <span class="paragraph_11">
            SES協業について
            <br />
            <br />
            <br />
            <br />
          </span>
          <span class="text_9"></span>
        </div>
        <img
          class="thumbnail_1"
          referrerpolicy="no-referrer"
          src="./assets/img/908b0a0b046bac26d36403284253ce50.png"
        />
      </div>
      <div class="image-wrapper_3 flex-row justify-between">
        <img
          class="image_7"
          referrerpolicy="no-referrer"
          src="./assets/img/969f292f569b81a1d3792caa2673ee3d.png"
        />
        <img
          class="image_8"
          referrerpolicy="no-referrer"
          src="./assets/img/c0923c3f5fe6d50f574b0d877f2a19d6.png"
        />
      </div>
      <div class="text-wrapper_10">
        <span class="paragraph_12">
          お問い合わせ題名名
          <br />
          <br />
        </span>
        <span class="text_10"></span>
      </div>
      <img
        class="image_9"
        referrerpolicy="no-referrer"
        src="./assets/img/d0d9908875f5a0b82e6d10cd5b81164e.png"
      />
      <div class="text-wrapper_11">
        <span class="paragraph_13">
          お問い合わせ内容&nbsp;(任意)
          <br />
          <br />
        </span>
        <span class="text_11"></span>
      </div>
      <img
        class="image_10"
        referrerpolicy="no-referrer"
        src="./assets/img/b01de1bbdcc939be9ef4ba0c2f6386b6.png"
      />
      <div class="block_5 flex-row">
        <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="./assets/img/b641e33398b1918ae6bff607f5afec27.png"
        />
        <div class="text-wrapper_12">Delivery</div>
      </div>
    </div>
    <div class="block_6 flex-col">
      <div class="text-wrapper_13">
        <span class="paragraph_15">
          Copyright&nbsp;©&nbsp;タッセ株式会社&nbsp;All&nbsp;rights&nbsp;reserved.
          <br />
        </span>
        <span class="text_13"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />