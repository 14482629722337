<template>
  <img
    referrerpolicy="no-referrer"
    src="./assets/img/59b172f4e6c79c56c74588c0501ef9ce.png"
    @click="$router.push('/')"
  />
</template>
<script>
export default {

}
</script>
<style scoped>
</style>