<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="group_1 flex-row">
        <div class="image-wrapper_1 flex-col">
          <top-logo class="image_1"></top-logo>
        </div>
        <div class="box_2 flex-col justify-between">
          <top-nav></top-nav>
        </div>
      </div>
      <div class="text-wrapper_1">
        <span class="paragraph_1">
          わたしたちと未来を変える
          <br />
          有望な人材を募集。
          <br />
        </span>
        <span class="paragraph_2"><br /></span>
      </div>
      <div class="group_2 flex-row">
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/396d63692eabba5eac434353cbfb9ffc.png"
        />
        <div class="text-wrapper_2">Still&nbsp;More</div>
      </div>
    </div>
    <div class="box_6 flex-col">
      <div class="block_1 flex-row justify-between">
        <div class="section_1 flex-col justify-between">
          <div class="text-wrapper_3">
            <span class="paragraph_4">
              JION&nbsp;US
              <br />
            </span>
            <span class="text_2"></span>
          </div>
          <span class="text_3">Okc会社に加入する理由?</span>
        </div>
        <div class="section_2 flex-col">
          <div class="group_3 flex-row justify-between">
            <div class="group_4 flex-col"></div>
            <span class="text_4">私たちと一緒に未来を形作りましょう</span>
          </div>
          <div class="text-wrapper_4 flex-row">
            <span class="text_5">
              私たちは、大手通販会社の顧客関係管理（CRM）システムの刷新に際して、Salesforce&nbsp;(SFDC)&nbsp;の導入支援を行いました。このプロジェクトは、顧客データの一元管理、営業プロセスの効率化、顧客満足度の向上を目指して実施されました。
            </span>
          </div>
        </div>
      </div>
      <div class="block_2 flex-row justify-between">
        <img
          class="thumbnail_1"
          referrerpolicy="no-referrer"
          src="./assets/img/1041b490fb4375f64cd02c9fb854db85.png"
        />
        <span class="paragraph_5">
          技術力と人材育成へのコミットメント
          <br />
        </span>
      </div>
      <div class="block_3 flex-row justify-between">
        <div class="box_7 flex-col"></div>
        <span class="paragraph_6">
          あなたも私たちの一員に
          <br />
        </span>
      </div>
      <div class="block_4 flex-col"></div>
    </div>
    <div class="box_8 flex-col">
      <div class="group_5 flex-col"></div>
      <img
        class="image_3"
        referrerpolicy="no-referrer"
        src="./assets/img/5e6b9fded1407e5f8bada8bbadf0bbc5.png"
      />
      <div class="text-wrapper_5 flex-row">
        <span class="text_6">採用について</span>
        <span class="text_7">“</span>
        <span class="paragraph_7">
          私私たちのミッションに共感し、私たちと共に成長し、成功を築きたいと思う方は、ぜひ私たちのチームに参加してください。現在募集中のポジションの詳細については、当ページ「募集要件」をご覧ください。
          <br />
          <br />
          私たちと一緒に、あなたのキャリアを次のレベルへと押し上げましょう。
        </span>
      </div>
    </div>
    <div class="box_9 flex-col">
      <span class="text_8">FAQ</span>
      <div class="group_6 flex-row justify-between">
        <div class="text-wrapper_6">
          <span class="paragraph_8">
            残業時間の平均はどれくらいですか？
            <br />
            <br />
          </span>
          <span class="text_9"></span>
        </div>
        <div class="section_3 flex-col"></div>
      </div>
      <span class="paragraph_9">
        平均約10時間です。残業は原則禁止となっており、残業を行うには上長の許可が必要です。
        <br />
        <br />
        <br />
      </span>
      <img
        class="image_4"
        referrerpolicy="no-referrer"
        src="./assets/img/25fe55446cf8171d7ea71a7a1251c5f4.png"
      />
      <div class="group_7 flex-row justify-between">
        <div class="text-wrapper_7">
          <span class="paragraph_10">
            リモートワークは導入されていますか？
            <br />
            <br />
            <br />
          </span>
          <span class="text_10"></span>
        </div>
        <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="./assets/img/8403deb4eec4428232f0136201998cf4.png"
        />
      </div>
      <img
        class="image_5"
        referrerpolicy="no-referrer"
        src="./assets/img/d6808b1a96ffd5088956e2ebbc4424c5.png"
      />
      <div class="group_8 flex-row justify-between">
        <div class="text-wrapper_8">
          <span class="paragraph_11">
            フレックス制度はありますか？
            <br />
            <br />
            <br />
            <br />
          </span>
          <span class="text_11"></span>
        </div>
        <div class="group_9 flex-col"></div>
      </div>
      <img
        class="image_6"
        referrerpolicy="no-referrer"
        src="./assets/img/48f8fc16678493387dc5513953401a06.png"
      />
      <div class="group_10 flex-row justify-between">
        <div class="text-wrapper_9">
          <span class="paragraph_12">
            昇給はありますか
            <br />
            <br />
            <br />
          </span>
          <span class="text_12"></span>
        </div>
        <div class="box_10 flex-col"></div>
      </div>
      <div class="group_11 flex-col">
        <div class="text-wrapper_10">
          <span class="paragraph_13">
            Copyright&nbsp;©&nbsp;タッセ株式会社&nbsp;All&nbsp;rights&nbsp;reserved.
            <br />
          </span>
          <span class="text_13"></span>
        </div>
      </div>
      <div class="group_12 flex-col"></div>
      <div class="group_13 flex-col"></div>
    </div>
    <div class="box_11 flex-col">
      <div class="image-wrapper_2 flex-row">
        <img
          class="image_7"
          referrerpolicy="no-referrer"
          src="./assets/img/e19c17d5179c55609d4ce507701996b4.png"
        />
      </div>
      <div class="group_14 flex-row">
        <span class="text_14">私たちの会社は何を提供しますか？</span>
        <span class="paragraph_14">
          01
          <br />
        </span>
        <div class="box_12 flex-col"></div>
        <span class="paragraph_15">
          02
          <br />
        </span>
      </div>
      <img
        class="image_8"
        referrerpolicy="no-referrer"
        src="./assets/img/9d674c484e712195205322f2ce0638e0.png"
      />
      <div class="text-wrapper_11 flex-col">
        <span class="paragraph_16">
          競争力のある給与体系
          <br />
          <br />
          <br />
        </span>
      </div>
      <img
        class="image_9"
        referrerpolicy="no-referrer"
        src="./assets/img/45b9b836f45da50b8c9dbd5ea279eb49.png"
      />
      <div class="text-wrapper_12 flex-col">
        <span class="paragraph_17">
          包括的な福利厚生
          <br />
          <br />
        </span>
      </div>
      <div class="text-wrapper_13 flex-col">
        <span class="paragraph_18">
          継続的な教育とプロフェッショナルな成長の機会
          <br />
          <br />
          <br />
        </span>
      </div>
      <div class="text-wrapper_14 flex-col">
        <span class="paragraph_19">
          支援的で協力的なチーム環境
          <br />
          <br />
          <br />
        </span>
      </div>
      <img
        class="image_10"
        referrerpolicy="no-referrer"
        src="./assets/img/b4fd9da4a36c7fba7a227097e192c54c.png"
      />
      <img
        class="image_11"
        referrerpolicy="no-referrer"
        src="./assets/img/bdef3b7246abfdf072ef5cd8cfc7eea6.png"
      />
      <img
        class="image_12"
        referrerpolicy="no-referrer"
        src="./assets/img/cdd7113ceab800b6b730b4efbe4a331b.png"
      />
      <div class="group_15 flex-col"></div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />