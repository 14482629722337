<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <div class="group_2 flex-row">
        <div class="image-wrapper_1 flex-col">
          <top-logo class="image_1"></top-logo>
        </div>
        <top-nav class="label_1"></top-nav>
      </div>
      <div class="text-wrapper_1">
        <span class="text_1">Everthing&nbsp;will&nbsp;be&nbsp;OK</span>
        <span class="text_2"></span>
      </div>
      <div class="text-wrapper_2">
        <span class="paragraph_1">
          ＯＫＣ株式会社
          <br />
        </span>
        <span class="text_3"></span>
      </div>
      <div class="group_3 flex-row">
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/09c176a3af6bf2f92b8ac95dc6ef1976.png"
        />
        <div class="text-wrapper_3">Still&nbsp;More</div>
      </div>
    </div>
    <div class="group_4 flex-col">
      <span class="paragraph_3">
        Servers
        <br />
        <br />
        <br />
      </span>
      <van-swipe autoplay="3000" @change="onChange">
        <van-swipe-item v-for="index in 4" :key="index">
          <div class="box_1 flex-row">
            <div class="block_1 flex-col">
              <div class="text-wrapper_4">
                <span class="paragraph_6">
                  SAP開発・導入支援
                  <br />
                </span>
                <span class="text_5"></span>
              </div>
              <span class="text_6">
                &nbsp;SAPソリューションのカスタマイズ開発及び導入支援を行い、企業の経営資源の最適化を促進します。業務プロセスの効率化と情報の可視化に貢献します
              </span>
              <div class="block_3 flex-row">
                <img
                  class="image_3"
                  referrerpolicy="no-referrer"
                  src="./assets/img/441f584285e65b0f25127318d1422d02.png"
                />
                <div class="text-wrapper_3">Still&nbsp;More</div>
              </div>
            </div>
            <img
              class="image_4"
              referrerpolicy="no-referrer"
              src="./assets/img/edf8a017e449286cc981a77349656814.png"
            />
            <img
              class="image_5"
              referrerpolicy="no-referrer"
              src="./assets/img/a15c6106ea2b97fddf685db0e67e4981.png"
            />
          </div>
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator flex-row">
            <span class="paragraph_4">
              0{{ current + 1 }}
              <br />
            </span>
            <div class="section_1 flex-col"></div>
            <span class="paragraph_5">
              04
              <br />
            </span>
          </div>
        </template>
      </van-swipe>
      <img
        class="image_6"
        referrerpolicy="no-referrer"
        src="./assets/img/79fe3b8a4c4e970f7a5bae9cc9a2f1d0.png"
      />
      <div class="box_2 flex-col"></div>
      <img
        class="image_7"
        referrerpolicy="no-referrer"
        src="./assets/img/695a5b8fd444330e8583e4fd503cd9eb.png"
      />
    </div>
    <div class="group_5 flex-col">
      <div class="box_3 flex-col">
        <div class="text-wrapper_6 flex-row">
          <span class="text_8">連絡先</span>
        </div>
        <div class="box_4 flex-row">
          <span class="text_9">OKC株式会社</span>
          <img
            class="image_8"
            referrerpolicy="no-referrer"
            src="./assets/img/c6ecef7727be27bc9fd39e06127f5d5e.png"
          />
          <div class="text-wrapper_7 flex-col justify-between">
            <span class="paragraph_8">
              Tel
              <br />
            </span>
            <span class="paragraph_9">
              03-6363-5674
              <br />
            </span>
          </div>
        </div>
        <div class="box_5 flex-row justify-between">
          <div class="text-wrapper_8">
            <span class="text_10">About</span>
            <span class="text_11">Company</span>
          </div>
          <div class="image-text_1 flex-row justify-between">
            <img
              class="image_9"
              referrerpolicy="no-referrer"
              src="./assets/img/4cb2609084baf5e2e0324cadf007d214.png"
            />
            <div class="text-group_1 flex-col justify-between">
              <span class="paragraph_10">
                Mail
                <br />
              </span>
              <span class="paragraph_11">
                OKC.hotmail.com
                <br />
              </span>
            </div>
          </div>
        </div>
        <div class="box_6 flex-row justify-between">
          <span class="text_12">
            ITの浸透が、人々の生活をより良い方向に変化させる」ことを目指しています。人々の生活をより豊かにするため、サービスに取り組んでまいります。
          </span>
          <div class="image-text_2 flex-row justify-between">
            <img
              class="image_10"
              referrerpolicy="no-referrer"
              src="./assets/img/dd35f7c6d64a875758b45af0bb6d8eca.png"
            />
            <div class="text-group_2 flex-col justify-between">
              <span class="paragraph_12">
                Adress
                <br />
              </span>
              <span class="paragraph_13">
                104-0061&nbsp;东京都中央区银座1-12-4N&amp;E&nbsp;BLD.7楼
                <br />
              </span>
            </div>
          </div>
        </div>
        <div class="text-wrapper_9 flex-row justify-between">
          <span class="text_13">会社の概要</span>
          <span class="text_14">取引方式</span>
        </div>
        <div class="box_7 flex-row justify-between">
          <div class="box_8 flex-row">
            <img
              class="image_11"
              referrerpolicy="no-referrer"
              src="./assets/img/20954b42ad44ac19b308f7f894bd9de6.png"
            />
            <div class="text-wrapper_3">Still&nbsp;More</div>
          </div>
          <img
            class="image_12"
            referrerpolicy="no-referrer"
            src="./assets/img/d91ec00973b42bc482615540f4bee9f8.png"
          />
        </div>
        <div class="text-wrapper_11 flex-row">
          <span class="text_16">2024/04</span>
          <span class="text_17">500</span>
          <span class="text_18">万円</span>
        </div>
        <div class="box_9 flex-row">
          <span class="text_19">資本金</span>
          <span class="text_20">設立</span>
          <img
            class="image_13"
            referrerpolicy="no-referrer"
            src="./assets/img/bec9c49ae8e5237b623806e579f8ec01.png"
          />
        </div>
      </div>
    </div>
    <div class="group_6 flex-col">
      <div class="group_7 flex-row">
        <span class="paragraph_15">
          Our&nbsp;Blog
          <br />
          <br />
          <br />
        </span>
        <van-swipe
          class="group_8 flex-row"
          autoplay="3000"
          @change="onChangeBottom"
          :width="620"
          style="overflow: unset"
        >
          <van-swipe-item
            class="image-wrapper_2 flex-col"
            v-for="index in 4"
            :key="index"
          >
            <img
              class="image_14"
              referrerpolicy="no-referrer"
              src="./assets/img/ae03282ae643ab561d79c5ed23091989.png"
            />
            <div class="text-wrapper_12">
              <span class="text_21"
                >I私はニュースのタイトルですI私はニュースのタイトルですI私はニュースのタイトルです</span
              >
            </div>
          </van-swipe-item>
          <template #indicator>
            <div class="custom-indicator-bottom">
              <span class="paragraph_16"> 0{{ currentBottom + 1 }} </span>
              <div class="box_10 flex-col"></div>
              <span class="paragraph_17"> 04 </span>
            </div>
          </template>
        </van-swipe>
      </div>

      <div class="group_9 flex-row">
        <div class="box_11 flex-row">
          <img
            class="image_17"
            referrerpolicy="no-referrer"
            src="./assets/img/5f1795bcbaadcca62544e1d33da2bc55.png"
          />
          <div class="text-wrapper_3">Still&nbsp;More</div>
        </div>
      </div>
      <div class="group_10 flex-col"></div>
    </div>
    <div class="group_11 flex-col">
      <div class="text-wrapper_14">
        <span class="paragraph_19">
          Copyright&nbsp;©&nbsp;タッセ株式会社&nbsp;All&nbsp;rights&nbsp;reserved.
          <br />
        </span>
        <span class="text_24"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      current: 0,
      currentBottom: 0,
    };
  },
  methods: {
    onChange (index) {
      this.current = index;
    },
    onChangeBottom (index) {
      this.currentBottom = index;
    },
  }
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />