<template>
  <div class="page flex-col">
    <div class="section_1 flex-col">
      <div class="image-wrapper_1 flex-row">
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/6361b2bfd57bc49a4f16d7493cbbb014.png"
        />
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/db8cb7fa25a00cb8e60629ef476ffbd0.png"
        />
      </div>
    </div>
    <div class="section_2 flex-col">
      <div class="text-group_5 flex-col justify-between">
        <div class="text-wrapper_1">
          <span class="paragraph_1">
            Everthing&nbsp;
            <br />
            will&nbsp;be
          </span>
          <span class="text_1"></span>
          <span class="paragraph_2">
            OK
            <br />
          </span>
          <span class="text_2"></span>
        </div>
        <div class="text-wrapper_2">
          <span class="paragraph_3">
            ＯＫＣ株式会社
            <br />
          </span>
          <span class="text_3"></span>
        </div>
      </div>
      <div class="box_1 flex-row">
        <div class="image-text_3 flex-row justify-between">
          <img
            class="label_1"
            referrerpolicy="no-referrer"
            src="./assets/img/4a94d880b7e9e11381e5e58b4048d419.png"
          />
          <div class="text-group_2">
            <span class="paragraph_4">
              Still&nbsp;More
              <br />
            </span>
            <span class="text_4"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="section_3 flex-col"></div>
    <div class="section_4 flex-col">
      <div class="text-wrapper_4">
        <span class="paragraph_6">
          Still&nbsp;More
          <br />
        </span>
        <span class="text_6"></span>
      </div>
      <img
        class="image_4"
        referrerpolicy="no-referrer"
        src="./assets/img/e7f9ec3e2abadb403a2b5c611a16c034.png"
      />
      <div class="group_2 flex-col">
        <span class="text_7">OKC株式会社</span>
        <div class="text-wrapper_5">
          <span class="text_8">About</span>
          <span class="text_9">Company</span>
        </div>
        <span class="text_10">
          ITの浸透が、人々の生活をより良い方向に変化させる」ことを目指しています。人々の生活をより豊かにするため、サービスに取り組んでまいります。
        </span>
        <div class="block_1 flex-row">
          <div class="image-text_4 flex-row justify-between">
            <img
              class="label_2"
              referrerpolicy="no-referrer"
              src="./assets/img/b0f71deaae935fb61673dbc073c84226.png"
            />
            <div class="text-group_3">
              <span class="paragraph_7">
                Still&nbsp;More
                <br />
              </span>
              <span class="text_11"></span>
            </div>
          </div>
        </div>
        <div class="text-wrapper_13 flex-row justify-between">
          <span class="paragraph_8">
            Tel
            <br />
          </span>
          <span class="text_12">会社の概要</span>
        </div>
        <div class="section_12 flex-row">
          <img
            class="label_3"
            referrerpolicy="no-referrer"
            src="./assets/img/af8f3ebefe511c0b45221d757d1a580c.png"
          />
          <span class="paragraph_9">
            03-6363-5674
            <br />
          </span>
          <div class="text-group_6 flex-col justify-between">
            <span class="text_13">2024/04</span>
            <span class="text_14">設立</span>
          </div>
        </div>
        <img
          class="image_5"
          referrerpolicy="no-referrer"
          src="./assets/img/f002e581ff47189a63758bbe1e3e1c21.png"
        />
        <div class="text-wrapper_14 flex-row justify-between">
          <span class="text_15">500</span>
          <span class="text_16">万円</span>
        </div>
        <div class="text-wrapper_15 flex-row justify-between">
          <span class="paragraph_10">
            Mail
            <br />
          </span>
          <span class="text_17">資本金</span>
        </div>
        <img
          class="label_4"
          referrerpolicy="no-referrer"
          src="./assets/img/c3019deac67a09306c4e5f5a2c154d23.png"
        />
        <span class="paragraph_11">
          OKC.hotmail.com
          <br />
        </span>
        <span class="text_18">取引方式</span>
        <img
          class="image_6"
          referrerpolicy="no-referrer"
          src="./assets/img/d7c5b9848cdac13eeb4ad31464e6413e.png"
        />
        <span class="paragraph_12">
          Adress
          <br />
        </span>
        <div class="section_13 flex-row">
          <img
            class="label_5"
            referrerpolicy="no-referrer"
            src="./assets/img/dc794d3e7744c59a9ef92b91f0ace18a.png"
          />
          <span class="paragraph_13">
            104-0061&nbsp;东京都中央区银座1-12-4N&amp;E&nbsp;BLD.7楼
            <br />
          </span>
          <div class="image-wrapper_6 flex-col justify-between">
            <img
              class="image_7"
              referrerpolicy="no-referrer"
              src="./assets/img/5334c0edf2a50bdde1e93c5e6576ea1b.png"
            />
            <img
              class="image_8"
              referrerpolicy="no-referrer"
              src="./assets/img/85bc019ce1d5c6324dcaffdef0093750.png"
            />
          </div>
        </div>
        <div class="block_4 flex-col"></div>
        <div class="block_5 flex-col"></div>
        <div class="block_6 flex-col"></div>
      </div>
    </div>
    <div class="box_5 flex-col">
      <span class="paragraph_15">
        Our&nbsp;Blog
        <br />
        <br />
        <br />
      </span>
      <img
        class="image_12"
        referrerpolicy="no-referrer"
        src="./assets/img/e19034a4ecd002306c7e663c0faccb8c.png"
      />
      <span class="text_20"
        >I私はニュースのタイトルですI私はニュースのタイトルですI私はニュースのタイトルです</span
      >
      <img
        class="image_13"
        referrerpolicy="no-referrer"
        src="./assets/img/502bc4339129812687391c27ffc92fe6.png"
      />
      <img
        class="image_14"
        referrerpolicy="no-referrer"
        src="./assets/img/0b443992aa5ad880c1e9e49ec8cdfe93.png"
      />
      <span class="text_21"
        >I私はニュースのタイトルですI私はニュースのタイトルですI私はニュースのタイトルです</span
      >
      <img
        class="image_15"
        referrerpolicy="no-referrer"
        src="./assets/img/1057cb36a7d3e53bd2abdd1dd24c67e4.png"
      />
      <div class="group_4 flex-col justify-end">
        <div class="block_7 flex-col">
          <div class="text-wrapper_10">
            <span class="paragraph_16">
              Copyright&nbsp;©&nbsp;タッセ株式会社&nbsp;All&nbsp;rights&nbsp;reserved.
              <br />
            </span>
            <span class="text_22"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="box_6 flex-col">
      <span class="paragraph_18">
        Servers
        <br />
        <br />
        <br />
      </span>
      <div class="block_8 flex-row justify-between">
        <div class="section_7 flex-col"></div>
        <div class="section_8 flex-col"></div>
        <div class="section_9 flex-col"></div>
        <div class="section_10 flex-col"></div>
      </div>
      <div class="text-wrapper_12">
        <span class="paragraph_19">
          SAP開発・導入支援
          <br />
        </span>
        <span class="text_24"></span>
      </div>
      <span class="text_25">
        SAPソリューションのカスタマイズ開発及び導入支援を行い、企業の経営資源の最適化を促進します。業務プロセスの効率化と情報の可視化に貢献します
      </span>
      <img
        class="image_20"
        referrerpolicy="no-referrer"
        src="./assets/img/90843c0ce83be78d35f0afd098e55cd0.png"
      />
      <img
        class="image_21"
        referrerpolicy="no-referrer"
        src="./assets/img/a0a967c03d4c93a6c6233cf883f17adb.png"
      />
      <div class="box_4 flex-col"></div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />