<template>
  <div class="page flex-col">
    <div class="box_1 flex-row">
      <div class="group_1 flex-col justify-between">
        <div class="text-wrapper_1">
          <span class="paragraph_1">
            その先の未来へ挑む。
            <br />
            人類の合理的な明るい未来ヘ。
            <br />
          </span>
          <span class="paragraph_2"><br /></span>
        </div>
        <div class="box_2 flex-row">
          <img
            class="image_1"
            referrerpolicy="no-referrer"
            src="./assets/img/d8ccb00d7a937d790473d42a72d2364f.png"
          />
          <div class="text-wrapper_2">Still&nbsp;More</div>
        </div>
      </div>
      <div class="group_2 flex-col">
        <div class="group_3 flex-row">
          <div class="image-wrapper_1 flex-col">
            <top-logo class="image_2"></top-logo>
          </div>

          <div class="group_4 flex-col justify-between">
            <top-nav></top-nav>
          </div>
        </div>
      </div>
    </div>
    <div class="box_3 flex-col">
      <div class="group_8 flex-row justify-between">
        <span class="paragraph_4">
          vioce
          <br />
          <br />
          <br />
        </span>
        <div class="text-wrapper_3">
          <span class="paragraph_5">
            ABOUT&nbsp;OKC
            <br />
          </span>
          <span class="text_2"></span>
        </div>
      </div>
      <span class="paragraph_6">
        人工知能や最新の技術と共生する世界は、SF映画の中のものだけではありません。
        <br />
        すでに秒進分歩のスピードで目まぐるしい変化を感じると思います。
        <br />
        あらゆる場面で生活を豊かにしてくれるAIとの共生社会の実現が、当社の使命です。
      </span>
      <img
        class="image_3"
        referrerpolicy="no-referrer"
        src="./assets/img/4fb333ee1dfeb898d825ef8ddd839aef.png"
      />
      <img
        class="image_4"
        referrerpolicy="no-referrer"
        src="./assets/img/066ef41eff0f3040beb7b9cf959ddf48.png"
      />
      <div class="group_9 flex-row">
        <img
          class="image_5"
          referrerpolicy="no-referrer"
          src="./assets/img/d9ffc808e1246702693aab6aeacf8816.png"
        />
        <div class="text-wrapper_2">Still&nbsp;More</div>
      </div>
    </div>
    <div class="box_4 flex-col">
      <div class="text-wrapper_5">
        <span class="paragraph_8">
          Copyright&nbsp;©&nbsp;タッセ株式会社&nbsp;All&nbsp;rights&nbsp;reserved.
          <br />
        </span>
        <span class="text_4"></span>
      </div>
    </div>
    <div class="box_5 flex-col">
      <div class="box_6 flex-row">
        <span class="paragraph_9">
          vioce
          <br />
          <br />
          <br />
        </span>
        <div class="group_10 flex-col">
          <div class="box_7 flex-row">
            <div class="text-wrapper_6">
              <span class="paragraph_10">
                認定資格
                <br />
              </span>
              <span class="text_5"></span>
            </div>
          </div>
          <div class="box_8 flex-row">
            <div class="text-wrapper_7">
              <span class="paragraph_11">
                Qualification
                <br />
              </span>
              <span class="text_6"></span>
            </div>
          </div>
          <div class="box_9 flex-row justify-between">
            <span class="paragraph_12">
              私たちと協働している
              <br />
              <br />
              <br />
              <br />
            </span>
            <img
              class="image_6"
              referrerpolicy="no-referrer"
              src="./assets/img/a0dd6d706c80e7b93824eaa824056c10.png"
            />
          </div>
        </div>
        <div class="group_11 flex-col">
          <div class="box_10 flex-row">
            <div class="text-wrapper_8">
              <span class="paragraph_13">
                アクセス
                <br />
              </span>
              <span class="text_7"></span>
            </div>
          </div>
          <div class="box_11 flex-row">
            <div class="text-wrapper_9">
              <span class="paragraph_14">
                Access
                <br />
              </span>
              <span class="text_8"></span>
            </div>
          </div>
          <div class="box_12 flex-row justify-between">
            <span class="paragraph_15">
              アクセス情報
              <br />
              <br />
              <br />
            </span>
            <div class="image-wrapper_2 flex-col">
              <img
                class="label_1"
                referrerpolicy="no-referrer"
                src="./assets/img/a869baccb01d2a940c582ce39cfadaab.png"
              />
            </div>
          </div>
        </div>
        <div class="group_12 flex-col">
          <div class="group_13 flex-row">
            <div class="text-wrapper_10">
              <span class="paragraph_16">
                沿革
                <br />
              </span>
              <span class="text_9"></span>
            </div>
          </div>
          <div class="group_14 flex-row">
            <div class="text-wrapper_11">
              <span class="paragraph_17">
                History
                <br />
              </span>
              <span class="text_10"></span>
            </div>
          </div>
          <div class="group_15 flex-row justify-between">
            <span class="paragraph_18">
              創業から現在までの軌跡
              <br />
            </span>
            <div class="image-wrapper_3 flex-col">
              <img
                class="label_2"
                referrerpolicy="no-referrer"
                src="./assets/img/72b7220cfbfae29ddaebf693bd8b3b2a.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="box_13 flex-row">
        <div class="box_14 flex-col">
          <div class="group_16 flex-row">
            <div class="text-wrapper_12">
              <span class="paragraph_19">
                企業理念
                <br />
              </span>
              <span class="text_11"></span>
            </div>
          </div>
          <div class="group_17 flex-row">
            <div class="text-wrapper_13">
              <span class="paragraph_20">
                Philosophy
                <br />
              </span>
              <span class="text_12"></span>
            </div>
          </div>
          <div class="group_18 flex-row justify-between">
            <span class="paragraph_21">
              お客様の満足を最優先に
              <br />
              <br />
              <br />
              <br />
            </span>
            <div class="image-wrapper_4 flex-col">
              <img
                class="label_3"
                referrerpolicy="no-referrer"
                src="./assets/img/86dc4d9b2ec50361f9a2eb35d39816e4.png"
              />
            </div>
          </div>
        </div>
        <div class="box_15 flex-col">
          <div class="group_19 flex-row">
            <div class="text-wrapper_14">
              <span class="paragraph_22">
                会社概要
                <br />
              </span>
              <span class="text_13"></span>
            </div>
          </div>
          <div class="group_20 flex-row">
            <div class="text-wrapper_15">
              <span class="paragraph_23">
                Outline
                <br />
              </span>
              <span class="text_14"></span>
            </div>
          </div>
          <div class="group_21 flex-row justify-between">
            <span class="paragraph_24">
              私たちの旅はまだ始まったばかりです
              <br />
              <br />
              <br />
              <br />
            </span>
            <div class="image-wrapper_5 flex-col">
              <img
                class="label_4"
                referrerpolicy="no-referrer"
                src="./assets/img/72466ef92763911ac3efa613c92f2476.png"
              />
            </div>
          </div>
        </div>
        <div class="box_16 flex-col">
          <div class="box_17 flex-row">
            <div class="text-wrapper_16">
              <span class="paragraph_25">
                ごあいさつ
                <br />
              </span>
              <span class="text_15"></span>
            </div>
          </div>
          <div class="box_18 flex-row">
            <div class="text-wrapper_17">
              <span class="paragraph_26">
                Greeting
                <br />
              </span>
              <span class="text_16"></span>
            </div>
          </div>
          <div class="box_19 flex-row justify-between">
            <span class="paragraph_27">
              ユーザー目線に立ったサービスで
              <br />
            </span>
            <div class="image-wrapper_6 flex-col">
              <img
                class="label_5"
                referrerpolicy="no-referrer"
                src="./assets/img/cb0712c5d5ed92ab63641f568d742788.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />