<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="box_2 flex-col"></div>
      <span class="text_1">OKC&nbsp;Information</span>
      <div class="box_3 flex-row">
        <top-logo class="image_1"></top-logo>
        <div class="group_1 flex-col justify-between">
          <top-nav></top-nav>
        </div>
      </div>
    </div>
    <div class="box_4 flex-col">
      <span class="text_2">2024/04/21&nbsp;&nbsp;&nbsp;&nbsp;14:21:44</span>
      <span class="text_3">
        5/16(木)〜18(土)に開催される&nbsp;e-messe&nbsp;kanazawa&nbsp;2024&nbsp;に「brox」が出展します。
      </span>
      <img
        class="image_2"
        referrerpolicy="no-referrer"
        src="./assets/img/249a12821be44916e5947688287e133c.png"
      />
      <span class="paragraph_1">
        &nbsp;&nbsp;&nbsp;&nbsp;文書のスキャンデータからオフィス系ファイルまでを横断して全文検索が可能なAIエンタープライズサーチです。インフォディオが独自開発したAI-OCRエンジンを搭載し、スキャンした画像の中の文字を高精度にデータ抽出いたします。併せてスマートOCRも展示しておりますので、スマートOCRのデモをご覧いただくことも可能です！messe&nbsp;kanazawa&nbsp;2024&nbsp;に「brox」が出展します。
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;文書のスキャンデータからオフィス系ファイルまでを横断して全文検索が可能なAIエンタープライズサーチです。インフォディオが独自開発したAI-OCRエンジンを搭載し、スキャンした画像の中の文字を高精度にデータ抽出いたします。併せてスマートOCRも展示しておりますので、スマートOCRのデモをご覧いただくことも可能です！messe&nbsp;kanazawa&nbsp;2024&nbsp;に「brox」が出展します。文書のスキャンデータからオフィス系ファイルまでを横断して全文検索が可能なAIエンタープライズサーチです。インフォディオが独自開発したAI-OCRエンジンを搭載し、スキャンした画像の中の文字を高精度にデータ抽出いたします。併せてスマートOCRも展示しておりますので、スマートOCRのデモをご覧いただくことも可能です！messe&nbsp;kanazawa&nbsp;2024&nbsp;に「brox」が出展します。
        <br />
        文書のスキャンデータからオフィス系ファイルまでを横断して全文検索が可能なAIエンタープライズサーチです。インフォディオが独自開発したAI-OCRエンジンを搭載し、スキャンした画像の中の文字を高精度にデータ抽出いたします。併せてスマートOCRも展示しておりますので、スマートOCRのデモをご覧いただくことも可能です！messe&nbsp;kanazawa&nbsp;2024&nbsp;に「brox」が出展します。
        <br />
      </span>
      <img
        class="image_3"
        referrerpolicy="no-referrer"
        src="./assets/img/e77ae2aa824c0a2efe5ee04e5cea4aff.png"
      />
      <span class="text_4" @click="$router.go(-1)">&lt;&nbsp;PREV</span>
    </div>
    <div class="box_5 flex-col">
      <div class="text-wrapper_1">
        <span class="paragraph_2">
          Copyright&nbsp;©&nbsp;タッセ株式会社&nbsp;All&nbsp;rights&nbsp;reserved.
          <br />
        </span>
        <span class="text_5"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />